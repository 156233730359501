@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(../fonts/Helvetica.woff) format("woff");
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url(../fonts/37AE3F_0_0.woff) format("woff");
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 800;
  font-display: swap;
  src: local("Gilroy"), url("../fonts/Gilroy-ExtraBold.woff2") format("woff2");
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 600;
  font-display: swap;
  src:  local("Gilroy"), url("../fonts/Gilroy-SemiBold.woff2") format("woff2");
}

body,
html {
  height: 100%;
  margin: 0;
  font-size: 62.5%;
}

body {
  font-family: Helvetica;
  font-size: 12px;
}

.purple {
  color: #6a476e;
}
.mint {
  color: #0eb48d;
}

.login-wrapper {
  height: 100%;
  background: #fff;
  padding: 160px 0 0 0;
}

.error-wrapper {
  height: 100%;
  background: #fff;
  padding-top: 120px;
}

.error-wrapper .jumbotron {
  background-color: transparent;
}

.display-block {
  display: block;
}

.dropdown-menu{
  left: auto;
  right: 0;
}

.btnTransferSpec{
  margin-right: 5px;
}

.view{
  cursor: pointer;
}

.open{
  background-color: #36b587 !important;
  color: #fff;
}

.display-none {
  display: none;
}

.form-control-upload{
  height: auto;
}

#wrapper {
  width: 100%;
  height: 100%;
  margin-top: -60px;
  padding-top: 55px;
}

#page-wrapper {
  padding: 10px 15px;
  height: 100%;
  margin: 55px 0 0 255px;
}

#sidebar-wrapper {
  width: 250px;
  height: 100%;
  position: fixed;
  background: #f1f1f1;
  margin-top: 0;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;

    padding: 20px 30px;
  }
}

.sidebar {
  background: #ffffff;
  font-family: Helvetica;
  font-size: 14px;
}
.sidebar a {
  color: #333;
}
.sidebar .sidebar-nav.navbar-collapse {
  padding-left: 0;
  padding-right: 0;
  background-color: #f1f1f1;
}
.sidebar .sidebar-search {
  padding: 15px;
}
.sidebar ul li {
  border-bottom: 1px solid #cccccc;
  display: block;
  width: 100%;
}

.sidebar ul li a:hover {
  background-color: #36b587;
  color: #fff;
  text-decoration: none;
}
.sidebar ul li a.active {
  background-color: #7a577e;
  color: #fff;
}
.sidebar .arrow {
  float: right;
}
.sidebar .fa.arrow:before {
  content: "\f104";
}
.sidebar .active > a > .fa.arrow:before {
  content: "\f107";
}
.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
  border-bottom: none !important;
}
.sidebar .nav-second-level li a {
  padding-left: 37px;
  position: relative;
  display: block;
  padding: 10px 15px 10px 37px;
  font-size: 15px;
}
.sidebar .nav-third-level li a {
  padding-left: 52px;
}
@media (min-width: 768px) {
  .sidebar {
    z-index: 1;
    position: absolute;
    width: 250px;
  }
  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }
}

.font-bold {
  font-weight: bold;
}

/* login.tpl */
.login {
  max-width: 420px;
  margin: 0 auto 0 auto;
  padding: 20px;
}

.login .form-signin .form-control {
  font-size: 16px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.login .form-signin input[type="text"] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -1px;
}
.login .form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.login .form-signin button[type="submit"] {
  margin-top: 26px;
  font-family: Helvetica;
  font-size: 23px;
  padding: 0 40px;
}

.login img.card-img {
  margin-bottom: 20px;
}

.btn-link {
  color: #333;
}

.btn-pplogin,
.btn-pplogin:focus {
  border: 5px solid #0eb48d;
  border-radius: 0;
  color: #ffffff;
  background-color: #0eb48d;
  padding: 10px 40px;
  font-size: 23px !important;
  font-family: Gilroy;
}
.btn-pplogin:hover,
.btn-pplogin:active,
.btn-pplogin.active,
.btn-pplogin:active:focus,
.btn-pplogin.active:focus {
  border-color: #0eb48d;
  color: #ffffff;
  background-color: #0eb48d;
}

.form {
  display: table;
  width: 100%;
}
.form .formRow {
  display: table-row;
}
.form .formRow .formCell {
  display: table-cell;
  padding: 5px;
}
.form .formRow .formCell .error {
  color: #f00;
  font-size: 12px;
  margin-top: 5px;
  display: none;
}
.form .formRow .formCell .loading {
  font-size: 12px;
  color: #f90;
  display: none;
}

.error {
  display: none;
}
.loading {
  color: #f90;
  display: none;
}

/* nav.tpl */
.knk-top-nav {
  background: #ffffff;
  border: 0;
  margin: 0;
  border-bottom: 1px solid #ccc;
  padding: 0;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.knk-top-nav .navbar-header a.navbar-brand,
a.navbar-brand:hover,
a.navbar-brand:active {
  color: #fff;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: 400;
  background-color: transparent;
}
.knk-top-nav .navbar-right {
  padding: 0 15px;
  color: #fff;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.knk-top-nav .navbar-right .switch-language {
  border: 0;
  color: #000000;
  font-size: 17px;
  font-family: Gilroy;
}
.knk-top-nav .navbar-right .switch-language:focus {
  box-shadow: none;
}
.knk-submit,
.knk-submit:focus {
  border: 2px solid #0eb48d;
  border-radius: 16px;
  color: #0eb48d;
  background-color: #fff;
  padding: 5px 20px;
  font-size: 14px;
  font-family: Helvetica;
}
.knk-submit:hover,
.knk-submit:active,
.knk-submit.active,
.knk-submit:active:focus,
.knk-submit.active:focus {
  border-color: #0eb48d;
  color: #ffffff;
  background-color: #0eb48d;
}

.knk-top-nav .navbar-right li {
  padding: 10px 15px;
  margin-top: 5px;
  float: left;
}
.knk-top-nav .navbar-right li button {
  border: 2px solid #36b587;
  background-color: #36b587;
  border-radius: 0;
  color: #fff;
  padding: 0 10px;
  font-size: 17px;
  font-family: Gilroy;
  line-height: normal;
}
.knk-top-nav .navbar-right li a:visited {
  color: #fff;
  font-size: 17px;
}
.knk-top-nav .navbar-right li a:hover {
  text-decoration: underline;
}
.knk-top-nav .navbar-right li p {
  display: inline-block;
  float: left;
  margin-right: 20px;
  color: #000000;
  font-size: 17px;
  font-family: Gilroy;
  margin-bottom: 0;
}
/* knk-search */
.knk-search-result {
  position: absolute;
  margin-left: 37px;
  margin-top: -1px;
  z-index: 100;
}
.knk-search-result .list-group-item:first-child {
  border-radius: 0;
}
.knk-search-result a {
  min-width: 200px;
}

.knk-search .knk-search-type {
  margin-left: -1px;
  width: auto;
}

.btn-icon {
  padding: 0;
  height: 28px;
  width: 28px;
  text-align: center;
  text-shadow: 1px 1px 1px #333;
  font-size: 11px;
  line-height: 28px;
}

.bottom_panel {
  background: #fff;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #aaa;
  width: 100%;
  padding: 10px 0;
}

.page-header {
  margin-top: 10px;
  padding-top: 0px;
  padding-bottom: 9px;
  margin: 0 0 20px;
  border-bottom: 1px solid #eee;
}
.page-header h1 {
  color: #000;
  font-family: Gilroy;
  font-size: 36px;
  background-image: linear-gradient(#f8e8f3, #f8e8f3);
  background-repeat: no-repeat;
  background-position-y: 100%;
  display: inline-block;
  background-size: 100% 20px;
  padding: 0 10px;
}

.grayout {
  background-color: #f5f5f5;
  color: #3c3c3c;
}

.bottom-wrapper {
  margin-top: 20px;
  padding: 0px;
  position: fixed;
  bottom: 0px;
  width: 250px;
}

.contact-data {
  padding: 20px;
  margin-bottom: 20px;
  font-family: Helvetica;
  font-size: 17px;
  border-top: 1px solid #ccc;
  color: #3c3c3c;
}
.contact-data h4 {
  font-weight: bold;
  font-size: 18px;
  font-family: Gilroy;
}

.fa-fw{
  margin-right: 10px;
}
.contact-data span {
  display: block;
  margin-bottom: 16px;
}
.contact-data span,
.contact-data span a {
  color: #000000;
  font-size: 15px;
}

.account-data {
  padding: 15px 20px;
  font-family: Helvetica;
  font-size: 17px;
  border-bottom: 1px solid #ccc;
  color: #3c3c3c;
}
.account-data h4 {
  font-size: 18px;
  font-family: Helvetica;
}
.account-data .purple {
  color: #000;
}
.account-data p {
  margin-bottom: 5px;
}
#side-menu > li > a {
  position: relative;
  display: block;
  padding: 15px 15px;
  font-size: 17px;
  font-family: Helvetica;
}

#side-menu .card-body{
  padding: 0;
}

#side-menu .card-header {
  position: relative;
  display: block;
  padding: 15px 15px;
  font-size: 17px;
  font-family: Gilroy;
  cursor: pointer;
}

#side-menu .card-header .arrow-down {
  display: inline-block;
  float: right;
  margin-top: 2px;
}

#side-menu .card-header .arrow-down.active {
  transform: rotate(180deg);
  margin-top: 4px;
}

#side-menu .card {
  background-color: #f1f1f1;
}

#side-menu > li > a .arrow-down {
  display: inline-block;
  float: right;
  margin-top: 2px;
}
#side-menu > li > a .arrow-down.active {
  transform: rotate(180deg);
  margin-top: 4px;
}
.app-label {
  padding: 5px;
  text-align: center;
  font-style: italic;
  font-weight: 400;
  background-color: #f1f1f1;
  color: #000000;
  border-top: 1px solid #ccc;
}

.error-message {
  margin-top: 128px;
}

.button-pill {
  margin-top: 20px;
}

.button-pill a,
.button-pill a:visited {
  border: 5px solid #0eb48d;
  border-radius: 25px;
  color: #0eb48d;
  background-color: #fff;
  padding: 10px 40px;
  font-size: 18px;
  font-family: Helvetica;
}
.button-pill a:hover,
a:active {
  border-color: #0eb48d;
  color: #ffffff;
  background-color: #0eb48d;
  text-decoration: none;
}
a:active {
  background-color: transparent;
}
.knk-submit,
.knk-submit:focus {
  border: 2px solid #36b587;
  border-radius: 0px;
  color: #fff;
  background-color: #36b587;
  padding: 5px 20px;
  font-size: 14px;
  font-family: Helvetica;
}
.knk-submit:hover,
.knk-submit:active,
.knk-submit.active,
.knk-submit:active:focus,
.knk-submit.active:focus {
  border-color: #0eb48d;
  color: #ffffff;
  background-color: #0eb48d;
}

.knk-form input {
  width: 320px;
  padding: 4px 10px;
  border: 1px solid #3c3c3c;
  border-radius: 2px;
}
.knk-form input[type="text"]:disabled {
  background: #f9f9f9;
  border: 1px solid #ccc;
}

.knk-form td {
  font-size: 14px;
}

/* datatables */
ul.pagination li.paginate_button a {
  color: #3c3c3c;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  outline: none;
}
ul.pagination li.paginate_button.active a {
  background-color: #0eb48d;
  color: #fff;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  text-decoration: none;
  border: 1px solid #ddd;
}

#modalContent div.row {
  padding: 5px 20px;
  font-size: 14px;
}

#modalContent input[type="text"] {
  height: 28px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  margin: 5px 0px;
  margin-right: 5px;
  padding: 5px;
}

.modify-msg {
  text-align: center;
}
.modify-msg span {
  margin-top: 5px;
  padding: 5px;
}

.loader-box {
  text-align: center;
  padding-bottom: 10px;
  font-size: 48px;
}

@keyframes blink {
  50% {
    color: transparent;
  }
}
.loader__dot {
  animation: 1s blink infinite;
}
.loader__dot:nth-child(2) {
  animation-delay: 250ms;
}
.loader__dot:nth-child(3) {
  animation-delay: 500ms;
}

/* table */
table.table-td-middle tr td {
  vertical-align: middle;
}

.list-group-item-warning {
  background-color: transparent;
  color: #212529;
}

.input-set-container {
  border-radius: .25rem;
  border: 1px solid rgba(0,0,0,.125);
  width: 100%;
  color: #495057;
  padding-bottom: .75rem;
}

.input-set-container .header {
  background-color: rgba(0,0,0,.03);
  padding: .75rem 1.25rem;
  border-bottom: 1px solid rgba(0,0,0,.125);
  margin: 0;
}

.input-set-container .input-group {
  margin: 0 .75rem .75rem 0;
  width: auto;
}

.input-set-container .input-group .input-icon{
  min-width: .7rem;
}

.input-container{
  display: inline-table;
}

.input-set-container .input-group .react-datepicker-wrapper {
  width: 7rem;
}

.input-set-container .input-group .form-control{
  border-radius: 0 .25rem .25rem 0;
  display: flex;
  align-items: center;
  padding-top: .7rem;
}

.input-set-container .inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.25rem 1.25rem .75rem;
}

@media (max-width: 1600px) {
  .input-set-container .inputs {
    justify-content: flex-start;
  }
}

.input-set-container .input-label {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #ced4da;
  padding: .2rem .75rem 0;
  border-right: none;
  border-radius: .25rem 0 0 .25rem;
}

.input-set-container .inputs .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.25rem .75rem .75rem;
}

.input-set-container .inputs .checkbox-label {
  margin: .1rem 0 0 .3rem;
}

.input-set-container .buttons {
  padding-bottom: .75rem;
  display: flex;
  justify-content: center;
}

.input-set-container .buttons button:not(:last-child) {
  margin-right: .5rem;
}


.input-group{
  display: -webkit-flex;
  display: -webkit-box;
  display: -webkit-flex;
  height: 100%;
}
.input-group .form-control {
  height: 38px;
}
.input-group-text {
  font-size: 12px;
  background-color: #f5f5f5;
}
.input-error-message {
  color: #910000;
  padding-top: 4px;
  margin: 0;
  min-height: 22px;
}
.btn-primary,
.btn-primary:hover,
.btn-primary:active {
  border-color: #36b587 !important;
  border-radius: 0;
  background-color: #36b587 !important;
  color: #fff;
}
.btn-primary:hover,
.btn-primary:active {
  text-decoration: underline;
}
.btn-primary:focus {
  box-shadow: none !important;
}
.btnReset {
  background-color: #910000;
  border-color: #910000;
  border-radius: 0;
}
.btnReset:hover,
.btnReset:active {
  text-decoration: underline;
  background-color: #910000 !important;
  border-color: #910000 !important;
}
.btnReset:focus {
  box-shadow: none !important;
}
.form-control:focus {
  box-shadow: 0 0 0 0.05rem rgba(0, 123, 255, 0.25);
}
.form-control.field-error {
  border-color: #910000;
}
.user-form #Role.form-control {
  height: 34px;
}
.btn-success,
.btn-success:hover,
.btn-success:active,
.btn-success:focus {
  color: #fff;
  background-color: #36b587 !important;
  border-color: #36b587 !important;
  border-radius: 0;
  box-shadow: none !important;
}
#form-filter .btn-primary,
#form-filter .btnReset {
  width: 100px;
  margin-top: 20px;
}
.modal-title.purple {
  color: #000;
  font-size: 19px;
}
#editModal .btnClose,
#importModal .btnClose {
  background-color: #36b587;
  border-radius: 0;
  color: #fff;
  font-size: 14px;
}
#editModal .btnClose:hover,
#editModal .btnClose:active,
#editModal .btnClose:focus,
#importModal .btnClose:hover,
#importModal .btnClose:active,
#importModal .btnClose:focus {
  text-decoration: underline;
  box-shadow: none !important;
}
/*#orders_wrapper .row:{
      width: 100%;
  }*/
#orders_wrapper {
  display: block;
}
.table-sm td,
.table-sm th {
  padding: 9px;
}
table.table-bordered.dataTable {
  border-collapse: collapse;
}
.text-warning {
  color: #8a6d3b !important;
}

a.btnEditOrder {
  color: #36b587;
}
.nav-tabs .nav-link {
  border: 1px solid #dee2e6;
  color: #000;
}
.nav-tabs .nav-link:active {
  background-color: transparent;
}
ul.pagination {
  display: inline-block;
}
ul.pagination > li {
  display: inline;
}
ul.pagination > li > a {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #000000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.page-navi p {
  text-align: left;
  display: inline-block;
  float: left;
}
#form-filter .form-control,
form[name="userForm"] .form-control {
  font-size: 14px;
}
.modal-header .close:focus {
  outline: none;
}
.loginPanel {
  border: 1px solid #ccc;
  padding: 40px;
  margin-top: 10%;
}
.loginPanel span.header {
  font-size: 20px;
  text-align: center;
  display: block;
}
.reset-paswrd input {
  width: 100%;
  margin-bottom: 20px;
}
#orders_filter {
  float: right;
}
.btn-warning.disabled,
.btn-warning:disabled,
.btn-warning,
.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled):active:focus {
  background-color: #fad05b;
  border-color: #fad05b;
  border-radius: 0;
  box-shadow: none;
}
.btn {
  font-size: 14px;
  border-radius: 0;
}
.btn-default {
  border: 1px solid #ccc;
}

.dropdown-toggle{
  margin-bottom: 10px;
}
#users button {
  min-width: 80px;
}
.passwordReminder{
  background: none!important;
  border: none;
  padding: 0!important;
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
.modal-content{
  width: 600px;
}
.modal-body{
  padding: 2rem;
}
.modal-body .row div p{
  margin-bottom: 0.5rem;
}
.loan-display{
  font-size: 16px;
}

.MuiDataGrid-columnHeaderTitle {
  font-size: 1.4rem;
  font-weight: 700!important;
}

.MuiBackdrop-root {
  background-color: rgba(0,0,0,0.25);
}

.MuiButtonBase-root.MuiPaginationItem-root {
  font-size: 1.3rem;
  line-height: 1;
}

.MuiButtonBase-root.MuiPaginationItem-root:focus {
  outline: 0;
}

.MuiFormHelperText-root {
  font-size: 1rem !important;
  margin-left: 2px !important;
}

.MuiFormControlLabel-root {
  height: fit-content;
}

.virtualizedTable__tableContainer {
    border: 1px solid rgba(224,224,224,1);
}

.virtualizedTable__flexContainer {
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.virtualizedTable__tableRowHover:hover {
    background-color: #F4F4F4;
}

.virtualizedTable__tableCell {
    flex: 1;
    border-bottom: 0px !important;
}

.ReactVirtualized__Table__headerRow {
    background-color: #F0F0F0;
    outline: 1px solid rgba(224,224,224,1);
}

.ReactVirtualized__Table__headerRow:hover {
    background-color: #F0F0F0;
}

.datagridHeader__filters .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle {
    line-height: 1;
    margin-top: 15px;
}

.datagridHeader__filters .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitleContainer {
    align-items: flex-start !important;
    overflow: visible;
}
.datagridHeader__filters .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitleContainer .DateRangePicker {
    line-height: 29px;
    overflow: visible;
}
.datagridHeader__filters .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitleContainer .DateRangePicker .DateRangePickerInput__withBorder {
    border-radius: 4px;
    border-color: rgb(190,190,190);
    overflow: visible;
    max-height: 31px;
}
.datagridHeader__filters .MuiDataGrid-columnHeaders * {
  overflow: visible !important;
}
.datagridHeader__filters .MuiDataGrid-columnHeaders .DateRangePickerInput .DateRangePicker_picker {
    overflow: hidden !important;
    border: 1px solid rgb(190,190,190);
    border-radius: 4px;
}
.datagridHeader__filters .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitleContainer .DateInput {
    width: 85px;
    background-color: transparent;
}
.datagridHeader__filters .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitleContainer .DateRangePickerInput_arrow_svg {
    width: 14px;
    height: 14px;
}
.datagridHeader__filters .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitleContainer .DateInput .DateInput_input {
    font-size: 10px;
    line-height: 20px;
    padding: 6px 14px 3px;
    background-color: transparent;
}
.datagridHeader__filters .MuiDataGrid-columnHeaders {
    overflow: visible;
    z-index: 2;
}
.datagridHeader__filters .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:not(:first-child) {
    border-left: 1px solid rgb(224,224,224);
}
.datagridHeader__filters .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator {
    display: none;
}
.datagridHeader__filters .MuiDataGrid-columnHeaders .DateRangePickerInput .showClearDates {
    margin: 0;
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover,
.MuiInputAdornment-root .MuiIconButton-root:hover,
.MuiInputAdornment-root .MuiIconButton-root:focus {
    background: transparent !important;
    outline: 0;
}

.MuiDataGrid-main .MuiDataGrid-overlay {
  z-index: 1;
}

.pagination__container .MuiIconButton-root.Mui-disabled {
    opacity: 0.38;
}

button:focus {
  outline: none !important;
}
